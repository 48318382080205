import { getEnvVariable } from "~shared/get-env-variable.ts";

const authOptions = {
	authenticationFlowType: "USER_PASSWORD_AUTH",
	region: getEnvVariable("REGION"),
	userPoolId: getEnvVariable("USER_POOL_ID"),
	identityPoolId: getEnvVariable("IDENTITY_POOL_ID"),
	userPoolWebClientId: getEnvVariable("COGNITO_CLIENT_ID"),
};
export const amplifyConfig = {
	Auth: authOptions,
	ssr: true,
};
