import { Close as CloseIcon } from "@mui/icons-material";
import { Alert, IconButton, Snackbar } from "@mui/material";
import { useLoaderData, useNavigation } from "@remix-run/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { TransitionDown, TransitionUp } from "~/components/transitions.tsx";
import {
	Alert as AlertType,
	TESTS_TIME_ACCELERATOR,
} from "~/constants/global.ts";
import { useClientAlert, useIsNarrowView } from "~/hooks/use-ui-store.ts";
import type { loader } from "~/root.tsx";

export const AUTO_HIDE_DURATION = 6000 * TESTS_TIME_ACCELERATOR;

export function Alerts() {
	const { alert: serverAlert } = useLoaderData<typeof loader>();
	const { clientAlert } = useClientAlert();

	const navigation = useNavigation();
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const { t } = useTranslation();
	const appIsBusy = navigation.state !== "idle";
	const isNarrowView = useIsNarrowView();
	const lastShownClientAlert = useRef<AlertType | undefined>();
	const lastShownServerAlert = useRef<AlertType | undefined>();
	const [alert, setAlert] = useState<AlertType | undefined>(undefined);

	useEffect(() => {
		if (
			serverAlert &&
			JSON.stringify(lastShownServerAlert.current) !==
				JSON.stringify(serverAlert)
		) {
			lastShownServerAlert.current = serverAlert;
			setAlert(serverAlert);
			setIsAlertOpen(true);
		}
	}, [serverAlert]);

	useEffect(() => {
		if (
			clientAlert &&
			JSON.stringify(lastShownClientAlert.current) !==
				JSON.stringify(clientAlert)
		) {
			lastShownClientAlert.current = clientAlert;
			setAlert(clientAlert);
			setIsAlertOpen(true);
		}
	}, [clientAlert]);

	useEffect(() => {
		if (appIsBusy && isAlertOpen) {
			handleAlertClose();
		}
	}, [appIsBusy, isAlertOpen]);

	const handleAlertClose = () => {
		setIsAlertOpen(false);
		lastShownServerAlert.current = undefined;
		lastShownClientAlert.current = undefined;
	};

	if (!alert) return null;

	return (
		<>
			<Snackbar
				open={isAlertOpen}
				autoHideDuration={AUTO_HIDE_DURATION}
				anchorOrigin={{
					vertical: isNarrowView ? "top" : "bottom",
					horizontal: "center",
				}}
				onClose={handleAlertClose}
				TransitionComponent={isNarrowView ? TransitionDown : TransitionUp}
			>
				<Alert
					severity={alert.severity}
					action={<CloseButton onClose={handleAlertClose} />}
				>
					{t(alert.message)}
				</Alert>
			</Snackbar>
		</>
	);
}

const CloseButton = ({ onClose }: { onClose: VoidFunction }) => {
	const { t } = useTranslation();
	return (
		<IconButton
			size="small"
			aria-label={t("close")}
			color="inherit"
			onClick={onClose}
		>
			<CloseIcon fontSize="small" />
		</IconButton>
	);
};
