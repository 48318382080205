import * as amplitude from "@amplitude/analytics-browser";
import { withEmotionCache } from "@emotion/react";
import {
	unstable_useEnhancedEffect as useEnhancedEffect,
	CssBaseline,
} from "@mui/material";
import type {
	LinksFunction,
	LoaderFunctionArgs,
	MetaFunction,
} from "@remix-run/node";
import { json } from "@remix-run/node";
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLoaderData,
	useLocation,
	useMatches,
} from "@remix-run/react";
import { redirect } from "@remix-run/router";
import type { ActionFunctionArgs } from "@remix-run/server-runtime";
import { withSentry } from "@sentry/remix";
import { Amplify, Auth } from "aws-amplify";
import { setDefaultOptions } from "date-fns";
import { de } from "date-fns/locale";
import { useEffect } from "react";
import * as React from "react";

import mainStylesheetUrl from "./styles/main.css?url";

import { Alerts } from "~/components/alerts";
import { ErrorScreen } from "~/components/error-screen";
import { amplifyConfig } from "~/constants/amplify-auth";
import ClientStyleContext from "~/hooks/client-style-context";
import { useChangeLanguage } from "~/hooks/use-change-language";
import { useIsNarrowViewSetup } from "~/hooks/use-ui-store.ts";
import Background from "~/images/forest-landscape.webp";
import { authenticateUser } from "~/services/auth.server";
import { getDevicesRedirectUrl } from "~/services/device.server";
import i18next, { localeCookie } from "~/services/i18next.server";
import { getSession } from "~/services/session.server";
import { addCookieToHeaders, log } from "~/utils/general.ts";
import { assert } from "~shared/assert.ts";
import "@emotion/styled";
import { getEnvVariable } from "~shared/get-env-variable.ts";

export const PAGE_TITLE = "smart cube 360 | App";

export const links: LinksFunction = () => {
	return [
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		{ rel: "stylesheet", href: mainStylesheetUrl },
		{
			rel: "preload",
			as: "font",
			href: "/fonts/source-sans-pro-v21-latin-600.woff2",
			type: "font/woff2",
			crossOrigin: "anonymous",
		},
		{
			rel: "preload",
			as: "font",
			href: "/fonts/source-sans-pro-v21-latin-regular.woff2",
			type: "font/woff2",
			crossOrigin: "anonymous",
		},
		{
			rel: "icon",
			type: "image/png",
			sizes: "32x32",
			href: "/favicon.png",
		},
		{
			rel: "apple-touch-icon",
			sizes: "180x180",
			href: "/favicons/apple-touch-icon.png",
		},
		{
			rel: "icon",
			type: "image/png",
			sizes: "32x32",
			href: "/favicons/favicon-32x32.png",
		},
		{
			rel: "icon",
			type: "image/png",
			sizes: "16x16",
			href: "/favicons/favicon-16x16.png",
		},
		{ rel: "manifest", href: "/site.webmanifest" },
		{ rel: "icon", href: "/favicon.ico" },
	];
};

export const meta: MetaFunction = () => {
	const title =
		getEnvVariable("DEPLOY_ENV").toLowerCase() === "prod"
			? PAGE_TITLE
			: `${getEnvVariable("DEPLOY_ENV").toUpperCase()} ${PAGE_TITLE}`;
	return [
		{ title },
		{ charset: "utf-8" },
		{
			name: "viewport",
			content:
				"width=device-width,initial-scale=1,viewport-fit=cover,maximum-scale=1",
		},
		{
			name: "theme-color",
			content: "#545356",
		},
	];
};

// eslint-disable-next-line custom-rules/authenticate-endpoints
export async function loader({ request }: LoaderFunctionArgs) {
	const { getAlert, getHeaders, setHasSession } = await getSession(request);

	const locale = await i18next.getLocale(request);
	const env: Record<string, string | undefined> = {
		LATEST_COMMIT_DATE: getEnvVariable("LATEST_COMMIT_DATE"),
		LATEST_COMMIT_SHA: getEnvVariable("LATEST_COMMIT_SHA"),
		USER_POOL_ID: getEnvVariable("USER_POOL_ID"),
		IDENTITY_POOL_ID: getEnvVariable("IDENTITY_POOL_ID"),
		COGNITO_CLIENT_ID: getEnvVariable("COGNITO_CLIENT_ID"),
		REGION: getEnvVariable("REGION"),
		IOT_ENDPOINT: getEnvVariable("IOT_ENDPOINT"),
		BASE_URL: getEnvVariable("BASE_URL"),
		DEPLOY_ENV: getEnvVariable("DEPLOY_ENV"),
		SENTRY_DSN: getEnvVariable("SENTRY_DSN"),
		AMPLITUDE_TOKEN: getEnvVariable("AMPLITUDE_TOKEN"),
		NODE_ENV: getEnvVariable("NODE_ENV"),
	};
	Object.entries(env).forEach(([key, value]) => {
		if (value === undefined) {
			throw new Error(`${key} env variable is undefined`);
		}
	});
	const alert = getAlert();
	setHasSession();

	const headers = await getHeaders();
	const headersWithLocaleCookie = addCookieToHeaders(
		headers,
		await localeCookie.serialize(locale),
	);

	return json(
		{
			locale,
			env: env as Record<string, string>,
			alert,
		},
		{
			headers: headersWithLocaleCookie,
		},
	);
}

export async function action({ request }: ActionFunctionArgs) {
	const user = await authenticateUser(request);
	const body = await request.formData();
	const section = body.get("section")?.toString();
	assert(section, "section is required");
	if (section === "devices") {
		const redirectUrl = await getDevicesRedirectUrl(user.email);
		return redirect(redirectUrl);
	} else {
		return redirect(`/${section}`);
	}
}

Amplify.configure(amplifyConfig);

interface DocumentProps {
	children?: React.ReactNode;
	title?: string;
	errorMessageComponent?: React.ReactNode;
}

const Document = withEmotionCache(
	({ children, title, errorMessageComponent }: DocumentProps, emotionCache) => {
		// || {} necessary for error boundary
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		const { locale, env } = useLoaderData<typeof loader>() || {};
		useChangeLanguage(locale);
		useIsNarrowViewSetup();
		useAmplitude();

		useEffect(() => {
			setDefaultOptions({ locale: de });
		}, [locale]);

		const clientStyleData = React.useContext(ClientStyleContext);

		useEnhancedEffect(() => {
			emotionCache.sheet.container = document.head;
			const tags = emotionCache.sheet.tags;
			emotionCache.sheet.flush();
			tags.forEach((tag) => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
				(emotionCache.sheet as any)._insertTag(tag);
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
			(clientStyleData as any).reset();
		}, []);

		return (
			<html lang={locale}>
				<head>
					{title ? <title>{title}</title> : null}
					<Meta />
					<meta
						name="emotion-insertion-point"
						content="emotion-insertion-point"
					/>
					<Links />
				</head>
				<body id="root">
					<EnvVars env={env} />
					<RootLayout>{errorMessageComponent ?? children}</RootLayout>
					{!errorMessageComponent && <Alerts />}
					<ScrollRestoration />
					<Scripts />
				</body>
			</html>
		);
	},
);

function RootLayout({ children }: { children: React.ReactNode }) {
	return (
		<main
			className="h-[100svh] w-[100svw] overflow-hidden bg-cover bg-center"
			style={{
				backgroundImage: `url(${Background})`,
				paddingBottom: "env(safe-area-inset-bottom)",
			}}
		>
			{children}
		</main>
	);
}

const EnvVars = ({ env }: { env: Record<string, string> }) => {
	return (
		<>
			{/*necessary for production*/}
			<script
				dangerouslySetInnerHTML={{
					__html: `window.env = ${JSON.stringify(env)}`,
				}}
			/>

			{/*necessary for dev server*/}
			<script
				dangerouslySetInnerHTML={{
					__html: `window.process = ${JSON.stringify({ env })}`,
				}}
			/>
		</>
	);
};

function Root() {
	return (
		<Document>
			<CssBaseline />
			<Outlet />
		</Document>
	);
}

export default withSentry(Root);

export const ErrorBoundary = () => {
	return <Document errorMessageComponent={<ErrorScreen />} />;
};

function useAmplitude() {
	const matches = useMatches();
	const location = useLocation();

	useEffect(() => {
		if (typeof globalThis === "undefined") {
			return;
		}
		const matchesLength = matches.length;
		const deepestRoute = matches.at(matchesLength - 1);
		if (deepestRoute) {
			const route = deepestRoute.id.replace("routes/", "");
			const params = deepestRoute.params;
			const trackProperties = { page: route, ...params };
			amplitude.track("VIEW_PAGE", trackProperties);
		}
	}, [location.pathname, matches]);

	useEffect(() => {
		async function fetchUser() {
			try {
				const user = (await Auth.currentAuthenticatedUser()) as {
					username: string;
				};
				amplitude.setUserId(user.username);
			} catch (error) {
				log.error("amplitude error fetching user", error as never);
			}
		}

		void fetchUser();
	}, []);
}
